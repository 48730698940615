<template>
  <div>
    <div
      :class="['input', inputStatus, { error: hasError }]"
      @click="start"
      @keyup.tab="start"
    >
      <div :class="['input__content', inputStatus, { error: hasError }]">
        <div :class="['input__content-prompt', inputStatus]">
          {{ title }}
        </div>
        <input
          ref="input"
          v-model="inputContent"
          :class="['input__content-input', inputStatus, { error: hasError }]"
          @blur="inputChanged"
        />
      </div>
    </div>
    <transition>
      <div v-if="hasError" class="input__error">
        <div class="input__error-image">
          <img src="@/assets/img/new-error.svg" alt="phone" />
        </div>
        <p class="input__error-text">{{ errorText }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DoubleInput',
  props: {
    title: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputContent: '',
      inputStatus: '',
    }
  },
  methods: {
    start() {
      this.inputStatus = 'active'
      this.$refs.input.focus()
      this.$emit('input-focus', this.inputContent)
    },
    inputChanged() {
      if (!this.inputContent.length) {
        this.inputStatus = ''
      }
      this.$emit('input-changed', this.inputContent)
    },
  },
}
</script>

<style lang="sass" scoped>
.input
  display: flex
  width: 100%
  height: 5rem
  margin-bottom: 2rem
  border-radius: 1rem
  background: #F6F8F8
  cursor: pointer

  &.error
    margin-bottom: 0
    border: 1px solid $textError

  &__content
    flex: 1
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0.6rem 1.2rem
    border-radius: 1rem
    &.error
      background: #FCECEF

    &-input
      width: 100%
      height: 0
      padding: 0
      border-width: 0
      outline: none
      font-family: $secondaryFont
      font-weight: 400
      font-size: 0
      transition: height .3s ease
      background: #F6F8F8
      color: $textPrimary
      &.active
        height: 2.5rem
        font-size: 1.4rem
      &.error
        background: #FCECEF

    &-prompt
      font-family: $secondaryFont
      font-weight: 400
      font-size: 1.4rem
      color: $textSecondary
      transition: all .3s ease
      &.active
        margin-bottom: .4rem
        font-size: 1.2rem
        line-height: 1.6rem

  &__error
    display: flex
    align-items: center
    height: 2rem
    font-family: $secondaryFont
    font-size: 1.2rem
    line-height: 1.6rem
    color: $textError
    &-image
      margin-top: 2px
      margin-right: 2px
</style>
