<template>
  <section class="phones">
    <div v-for="branch in contacts" :key="branch.id" class="phones__item">
      <div>
        <div class="phones__item-title font-section">{{ branch.title }}</div>
        <div v-if="branch.name" class="font2-18">{{ branch.name }}</div>
      </div>
      <div class="font2-18">
        <div class="phones__item-contact">
          <img :src="imageUrl('contacts/email.svg')" alt="mail" />
          {{ branch.email }}
        </div>
        <div class="phones__item-contact">
          <img :src="imageUrl('contacts/phone.svg')" alt="mail" />
          {{ format(branch.phone) }}
        </div>
      </div>
    </div>
    <div v-if="contacts.length % 3 === 2" class="phones__item mod-empty" />
  </section>
</template>

<script>
import { formattedPhone } from '@/helpers/formatPhone'
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'ContactsPhones',
  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageUrl,
      format: formattedPhone,
    }
  },
}
</script>

<style lang="sass" scoped>
.phones
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  padding: 0 $desktopHorizontalPaddings
  @media (max-width: 1023px)
    flex-direction: column
    gap: 1.6rem
    padding: 0 $mobileHorizontalPaddings

  &__item
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 32.5%
    height: 22rem
    padding: 3rem
    margin-bottom: 2rem
    background: $backgroundGray
    border-radius: 3rem
    @media (max-width: 1023px)
      width: 100%
      height: 14rem
      padding: 2rem
      margin-bottom: 0
      border-radius: 1.6rem
    &.mod-empty
      background: transparent
      @media (max-width: 1023px)
        display: none
    &-title
      margin-bottom: 8px

    &-contact
      display: flex
      align-items: center
      gap: 0.8rem
      margin-top: 0.8rem
      font-weight: 600
      img
        width: 1.6rem
        height: 1.6rem
        @media (max-width: 1023px)
          width: 1.6rem
          height: 1.6rem
</style>
