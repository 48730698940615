<template>
  <section class="container">
    <div class="container__block mod-image">
      <div
        :style="
          isFromBack
            ? { 'background-image': `url( ${image})` }
            : {
                'background-image': 'url(' + imageUrl(image) + ')',
              }
        "
        class="container__block-image"
      />
    </div>
    <div class="container__block mod-content">
      <slot />
    </div>
  </section>
</template>

<script>
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'TwoSideComponent',
  props: {
    image: {
      type: String,
      default: '',
    },
    isFromBack: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.container
  display: flex
  padding: 0 $desktopHorizontalPaddings
  border-top: 1px solid $tileBackground
  border-bottom: 1px solid $tileBackground
  @media (max-width: 1023px)
    flex-direction: column
    padding: 0 $mobileHorizontalPaddings
  &__block
    width: 50%
    border-left: 1px solid $tileBackground
    @media (max-width: 1023px)
      width: 100%
    &.mod-image
      padding: 2.4rem
      @media (max-width: 1023px)
        padding: 1.6rem
        border-right: 1px solid $tileBackground
        border-bottom: 1px solid $tileBackground

    &.mod-content
      padding: 4rem 3.4rem 3.4rem
      border-right: 1px solid $tileBackground
      @media (max-width: 1023px)
        padding: 2.4rem 1.6rem

    &-image
      height: 50.1rem
      border-radius: 30px
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      @media (max-width: 1023px)
        height: 29.5rem
</style>
