import Vue from 'vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import VueSecureHTML from 'vue-html-secure'
Vue.use(VueSecureHTML)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

import Meta from 'vue-meta'
Vue.use(Meta)

import './assets/styles/main.sass'
import App from './App.vue'
import router from './router'
import { store } from './store'

Vue.config.productionTip = false

import VueYandexMetrika from 'vue-yandex-metrika'

Vue.use(VueYandexMetrika, {
  id: 97076449,
  router,
  env: process.env.NODE_ENV,
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
