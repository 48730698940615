<template>
  <section>
    <div v-for="schedule in content" :key="schedule.title" class="content">
      <h2 class="content__title font-subtitle">{{ schedule.title }}</h2>
      <div
        v-for="item in schedule.timetable"
        :key="item.label"
        class="content__item"
      >
        <div class="item__description">
          <div class="item__description-label">{{ item.label }}</div>
          <div class="item__description-value">{{ item.value }}</div>
        </div>
        <div v-if="item.note" class="item__note font2-18">
          {{ item.note }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WorkSchedule',
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="sass" scoped>
.content
  &:first-child
    margin-bottom: 6rem
    @media (max-width: 1023px)
      margin-bottom: 3.2rem

  &__title
    margin-bottom: 4rem
    @media (max-width: 1023px)
      margin-bottom: 2.4rem
  &__item
    margin-bottom: 1.6rem
    @media (max-width: 1023px)
      margin-bottom: 0.8rem
    .item__description
      display: flex
      align-items: center
      &-label
        width: 7rem
        font-family: $secondaryFont
        font-size: 1.4rem
        line-height: 1.8rem
        color: $textSecondary
      &-value
        font-size: 2rem
        font-weight: 500
        line-height: 2.4rem
        letter-spacing: -0.005em
    .item__note
      margin-top: 0.8rem
      margin-left: 7rem
      color: $textSecondary
      @media (max-width: 1023px)
        margin-top: 0.4rem
</style>
