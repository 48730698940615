<template>
  <section v-if="list" class="tariffs">
    <h2 class="tariffs__title">{{ list.title }}</h2>
    <price-list :layout="content" :list="list.items" />
  </section>
</template>

<script>
import PriceList from '@/components/PriceList.vue'

export default {
  name: 'ServicesList',
  components: { PriceList },
  props: {
    list: {
      type: Object,
      default: null,
    },
    content: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="sass" scoped>
.tariffs
  padding: $desktopVerticalPaddings $desktopHorizontalPaddings
  @media (max-width: 1023px)
    padding: $mobileVerticalPaddings $mobileHorizontalPaddings

  &__title
    margin-bottom: 40px
    font-weight: 500
    font-size: 4rem
    line-height: 4.8rem
    letter-spacing: -0.005em
    @media (max-width: 1023px)
      margin-bottom: 32px
      font-size: 3.2rem
      line-height: 4rem

:deep(.list__note)
  margin-bottom: 60px
</style>
