<template>
  <section>
    <page-header
      :title="content.title"
      :description="content.description"
      :image="content.image"
      :button="content.button"
      :mail-to="content.mailTo"
      v-on="$listeners"
    />
    <div ref="anchor">
      <about-description />
    </div>
    <about-video />
  </section>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import AboutDescription from '@/views/AboutPage/components/AboutDescription.vue'
import AboutVideo from '@/views/AboutPage/components/AboutVideo.vue'
import { headerBlock } from '@/content/about'
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'AboutPage',
  metaInfo: {
    title: 'Качественные услуги с 2008 года',
    meta: [
      {
        name: 'description',
        content:
          'Оказываем услуги грузовому транспорту на территории Новороссийска с 2008 года. Грузовое СТО, парковка и продажа техники в одном месте.',
      },
      {
        name: 'keywords',
        content:
          'новороссийский автотерминал, парковка новороссийск, парковки новороссийска, грузовое СТО новороссийск, грузовая техника VOLVO, грузовая техника RENAULT TRUCKS, грузовая техника HONGYAN, грузовая техника SHACMAN, грузовая техника HOWO, грузовая техника FOTON, грузовая техника FAW, грузовая техника SITRAK, грузовая техника DONGFENG',
      },
    ],
  },
  components: { PageHeader, AboutDescription, AboutVideo },
  mixins: [offsetMixin],
  data() {
    return {
      content: headerBlock,
    }
  },
}
</script>
