<template>
  <section class="timetable">
    <two-side-component :image="content.image">
      <work-schedule :content="content.schedules" />
    </two-side-component>
  </section>
</template>

<script>
import TwoSideComponent from '@/components/TwoSideComponent.vue'
import WorkSchedule from '@/components/WorkSchedule.vue'
import { imageUrl } from '@/helpers/imageLoading'
import { timetableBlock } from '@/content/contacts'

export default {
  name: 'ContactsTimetable',
  components: { TwoSideComponent, WorkSchedule },
  data() {
    return {
      imageUrl,
      content: timetableBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.timetable
  padding: 100px 0
  @media (max-width: 1023px)
    padding: $mobileVerticalPaddings 0
</style>
