import { mailTo } from '@/content/emailsList'

export const headerBlock = {
  image: 'contacts/contacts-header.jpg',
  title: 'Свяжитесь с нами удобным для вас образом!',
  description:
    'Если у вас есть вопросы или предложения&nbsp;- заполните форму или свяжитесь с необходимым вам отделом.',
  button: 'Задать вопрос',
  mailTo: mailTo.contacts,
}

export const timetableBlock = {
  image: 'contacts/contacts-timetable.png',
  schedules: [
    {
      title: 'График работы',
      timetable: [
        { label: 'ПН-ПТ', value: '09:00 - 18:00' },
        { label: 'Обед', value: '13:00 - 14:00' },
        { label: 'СБ,ВС', value: 'Выходной' },
        { label: 'Тел.', value: '+7 988 314-79-26' },
      ],
    },
    {
      title: 'Автотерминал',
      timetable: [
        {
          label: 'ПН-ВС',
          value: 'Круглосуточно',
          note: 'Без выходных и праздников',
        },
        { label: 'Тел.', value: '+7 918 460-63-32' },
      ],
    },
  ],
}

export const requisitesBlock = {
  title: 'Реквизиты компании',
  list: {
    common: [
      {
        label: 'Наименование',
        value:
          'Общество с ограниченной ответственностью «Новороссийский автотерминал»',
      },
      {
        label: 'Юридический адрес',
        value:
          '353998, Россия, Краснодарский кр, г.о. Новороссийск,  с. Владимировка, тер. Автодорога «Новороссийск-Керченский пролив»,  км. 10-й, д.7',
      },
      {
        label: 'Фактический адрес',
        value:
          '353998, Россия, Краснодарский кр, г.о. Новороссийск,  с. Владимировка, тер. Автодорога «Новороссийск-Керченский пролив»,  км. 10-й, д.7',
      },
      {
        label: 'ИНН',
        value: '2315118225',
      },
      {
        label: 'КПП',
        value: '231501001',
      },
      {
        label: 'ОГРН',
        value: '1052309120599',
      },
      {
        label: 'ОКПО',
        value: '78241880',
      },
      {
        label: 'ОКВЭД',
        value: '52.21.24',
      },
      {
        label: 'ОКАТО',
        value: '03420380000',
      },
    ],
    firstBank: [
      {
        label: 'Р/сч',
        value: '40702810652460102132',
      },
      {
        label: 'Банк',
        value:
          'Краснодарское отделение № 8619 ПАО «Сбербанк России» г.Краснодар',
      },
      {
        label: 'К/с',
        value: '30101810100000000602',
      },
      {
        label: 'БИК',
        value: '040349602',
      },
    ],
    secondBank: [
      {
        label: 'Р/сч',
        value: '40702810000750000102',
      },
      {
        label: 'Банк',
        value: '000 КБ «КУБАНЬ КРЕДИТ», г.Краснодар',
      },
      {
        label: 'К/с',
        value: '30101810200000000722',
      },
      {
        label: 'БИК',
        value: '040349722',
      },
    ],
  },
  button: {
    title: 'Скачать бланк',
    icon: 'download.svg',
  },
}
