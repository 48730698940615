<template>
  <div id="app">
    <vue-extend-layouts />
  </div>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'
export default {
  name: 'App',
  metaInfo: {
    title: 'Новороссийский терминал',
    titleTemplate: 'Новороссийский терминал | %s',
  },
  components: { VueExtendLayouts },
}
</script>
