import { mailTo } from '@/content/emailsList'

export const headerBlock = {
  image: 'services-header.jpg',
  title: 'Оказываем услуги автостоянки грузового автотранспорта',
  description:
    'Для получения консультации по аккредитации заполните форму или свяжитесь с нами по телефону&nbsp;<span>+7&nbsp;918&nbsp;460-63-32</span>',
  button: 'Задать вопрос',
  mailTo: mailTo.services,
}

export const servicesBlock = {
  title: 'Наши тарифы:',
  serviceTitle: 'Название услуги',
  priceTitle: 'Цена в т.ч НДС',
  note: 'Стоимость услуг для грузового автотранспорта с 01.11.2024г.',
}

export const tiresBlock = {
  title: 'Наши тарифы:',
  serviceTitle: 'Название услуги',
  priceTitle: 'Цена в т.ч НДС',
  note: 'Стоимость устанавливается в зависимости от сложности проводимых работ, подробности уточняйте у менеджеров',
}
