<template>
  <section class="services">
    <h2 class="services__title font-title">{{ content.title }}</h2>
    <div class="wrapper">
      <div
        v-for="(tile, index) in content.tiles"
        :key="index"
        :class="[
          'services__tile',
          tile.picture ? 'mod-picture' : 'mod-content',
        ]"
      >
        <div
          v-if="tile.picture"
          :style="{ 'background-image': 'url(' + imageUrl(tile.picture) + ')' }"
          class="services__tile-image"
        />
        <div v-else class="services__tile-content">
          <div
            class="content__icon"
            :style="{ 'background-image': 'url(' + imageUrl(tile.icon) + ')' }"
          />
          <div>
            <div v-html-safe="tile.text" class="content__text" />
            <navigation-button
              :text="tile.button"
              @clicked="$router.push({ name: tile.page })"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NavigationButton from '@/components/common/NavigationButton.vue'
import { servicesBlock } from '@/content/home'
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'HomeServices',
  components: { NavigationButton },
  data() {
    return {
      content: servicesBlock,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.services
  padding: $desktopVerticalPaddings $desktopHorizontalPaddings 100px
  @media (max-width: 1023px)
    padding: $mobileVerticalPaddings $mobileHorizontalPaddings 40px

  &__title
    margin-bottom: 6rem
    @media (max-width: 1023px)
      margin-bottom: 3rem

  .wrapper
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    align-items: center
    @media (max-width: 1023px)
      flex-direction: column

  &__tile
    width: 20.1vw
    height: 15.7vw
    margin-bottom: 20px
    background-color: $tileBackground
    border-radius: 30px
    @media (max-width: 1023px)
      width: 100%
      height: 212px
      border-radius: 20px
    &-image
      width: 100%
      height: 100%
      border-radius: 30px
      background-position: center
      background-size: cover
      background-repeat: no-repeat
      @media (max-width: 1023px)
        border-radius: 20px
    &-content
      display: flex
      flex-direction: column
      justify-content: space-between
      height: 100%
      padding: 1.6vw
      @media (max-width: 1023px)
        width: 100%
        max-width: 500px
        padding: 20px
      .content__icon
        width: 3.1vw
        height: 3.1vw
        background-size: contain
        background-repeat: no-repeat
        @media (max-width: 1023px)
          width: 40px
          height: 40px
      .content__text
        margin-bottom: 0.8rem
        font-weight: 500
        font-size: 1.25vw
        line-height: 1.56vw
        letter-spacing: -0.005em
        @media (max-width: 1023px)
          font-size: 20px
          line-height: 24px
</style>
