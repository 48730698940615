<template>
  <header>
    <div class="header">
      <div class="header__main">
        <h1 v-html-safe="content.title" class="header__main-title" />
        <h3 class="header__main-description">
          {{ content.description }}
        </h3>
        <div class="main-button" @click="$emit('show-form', content.mailTo)">
          {{ content.button }}
        </div>
      </div>
      <div class="header__picture">
        <div class="header__picture-wrapper">
          <img :src="imageUrl(content.image)" alt="header" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { headerBlock } from '@/content/home'
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'HomeHeader',
  data() {
    return {
      content: headerBlock,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.header
  display: flex
  height: 66rem
  background: $textPrimary
  @media (max-width: 1023px)
    flex-direction: column
    height: auto
  &__main
    width: 50%
    padding: 12rem 13rem 0
    @media (max-width: 1023px)
      width: 100%
      padding: 5rem 2rem 6rem
    &-title
      margin-bottom: 4rem
      font-weight: 500
      font-size: 7rem
      line-height: 8.2rem
      color: $textWhite
      letter-spacing: -0.005em
      @media (max-width: 1919px) and (min-width: 1600px)
        font-size: 5rem
        line-height: 6rem
      @media (max-width: 1599px) and (min-width: 1024px)
        font-size: 3.8rem
        line-height: 4.8rem
      @media (max-width: 1023px)
        font-size: 3.7rem
        line-height: 4.6rem

  &__picture
    width: 50%
    @media (max-width: 1023px)
      width: 100%
      height: 23.5rem

    &-wrapper
      height: 100%
      img
        width: 100%
        height: 100%
        object-fit: cover
</style>
