<template>
  <div class="nav" @click="$emit('clicked')">
    <div class="nav__text">{{ text }}</div>
    <img src="@/assets/img/arrow.svg" alt="arrow" class="nav__arrow" />
  </div>
</template>

<script>
export default {
  name: 'NavigationButton',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
.nav
  display: flex
  align-items: center
  cursor: pointer
  &__text
    font-size: 1.8rem
    color: $buttonPrimary
    @media (max-width: 1023px)
      font-size: 1.6rem
  &__arrow
    width: 16px
    height: 16px
    margin-left: 8px
    @media (max-width: 1023px)
      width: 13px
      height: 13px
</style>
