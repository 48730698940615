<template>
  <form v-loading="loading" name="contacts" class="form-callback">
    <input v-model="form.surname" type="text" class="surname" />
    <double-input
      :title="content.placeholders.name"
      :has-error="hasNameError"
      :error-text="nameError"
      class="form-callback__input"
      @input-focus="hasNameError = false"
      @input-changed="getField('name', $event)"
    />
    <double-input
      :title="content.placeholders.email"
      :has-error="hasEmailError"
      :error-text="emailError"
      class="form-callback__input"
      @input-focus="hasEmailError = false"
      @input-changed="getField('email', $event)"
    />
    <double-text-area
      :title="content.placeholders.question"
      :has-error="hasQuestionError"
      :error-text="questionError"
      class="form-callback__input"
      @input-focus="hasQuestionError = false"
      @input-changed="getField('question', $event)"
    />
    <el-checkbox v-model="isAgree" class="form-callback__checkbox">
      {{ content.checkbox.text }}
      <a :href="content.checkbox.url" target="_blank">
        {{ content.checkbox.link }}
      </a>
    </el-checkbox>
    <button
      :class="['main-button', { disabled: !isAgree }]"
      @click.prevent="startRequest"
    >
      {{ content.button }}
    </button>
  </form>
</template>

<script>
import Api from '@/api'
import { formData } from '@/content/modals'
import DoubleInput from '@/components/common/Form/DoubleInput'
import DoubleTextArea from '@/components/common/Form/DoubleTextArea'

// eslint-disable-next-line
const EMAIL_VALIDATION_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

export default {
  name: 'LeadForm',
  components: {
    DoubleInput,
    DoubleTextArea,
  },
  props: {
    mailTo: {
      type: String,
      default: 'ceo@nat.ooo',
    },
  },
  data() {
    return {
      content: formData,
      form: {
        name: '',
        email: '',
        question: '',
        surname: '',
      },
      hasNameError: false,
      nameError: '',
      hasEmailError: false,
      emailError: '',
      hasQuestionError: false,
      questionError: '',
      isAgree: true,
      loading: false,
    }
  },
  computed: {
    hasValidationErrors() {
      return this.hasNameError || this.hasEmailError || this.hasQuestionError
    },
  },
  methods: {
    getField(field, value) {
      this.form[field] = value
    },
    validateData() {
      if (!this.form.name.length) {
        this.hasNameError = true
        this.nameError = 'Поле обязательно для заполнения'
      }
      if (!EMAIL_VALIDATION_REGEXP.test(this.form.email)) {
        this.hasEmailError = true
        this.emailError = this.form.email.length
          ? 'Указанная почта не существует '
          : 'Поле обязательно для заполнения'
      }
      if (!this.form.question.length) {
        this.hasQuestionError = true
        this.questionError = 'Поле обязательно для заполнения'
      }
    },

    startRequest() {
      if (this.isAgree) {
        this.hasNameError = false
        this.hasEmailError = false
        this.validateData()

        if (!this.hasValidationErrors) {
          let formData = new FormData()

          for (let key in this.form) {
            formData.append(key, this.form[key])
          }
          formData.append('target', this.mailTo)
          this.loading = true
          Api.sendMail(formData)
            .then(() => {
              this.$emit('requested', 'success')
            })
            .catch(() => {
              this.$emit('requested', 'error')
            })
            .finally(() => {
              this.loading = false
            })
        }
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.form-callback
  display: flex
  flex-direction: column

  .main-button.disabled
    background: $buttonDisabled
    cursor: not-allowed
    color: #B3B8C0
    &:hover
      background: $buttonDisabled
    &:active
      background: $buttonDisabled

  &__checkbox
    margin-bottom: 2rem
    @media (max-width: 1023px)
      margin-bottom: 2.4rem

  .surname
    display: block
    width: 2px
    height: 2px
    margin-bottom: -2px
    border: none
    outline: none
    color: $textWhite
    background: $textWhite
    cursor: default

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner
  background: $buttonPrimary

::v-deep .el-checkbox__inner
  width: 16px
  height: 16px
  &::after
    height: 8px
    left: 5px

::v-deep .el-checkbox
  color: #A7ADB6
  a
    color: #A7ADB6

::v-deep .el-checkbox__input.is-checked+.el-checkbox__label
  color: #A7ADB6
  a
    color: #A7ADB6

::v-deep .el-checkbox__label
  font-family: $secondaryFont
  font-weight: 400
  font-size: 1.2rem
  line-height: 1.6rem
</style>
