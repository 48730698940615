<template>
  <section class="description">
    <div class="description__wrapper">
      <div class="description__title font-subtitle">{{ content.title }}</div>

      <div class="description__image">
        <div
          :style="{
            'background-image': 'url(' + imageUrl(content.image) + ')',
          }"
          class="description__image-picture"
        />
      </div>

      <div
        v-html-safe="content.description"
        class="font2-18 description__content"
      />
    </div>
  </section>
</template>

<script>
import { imageUrl } from '@/helpers/imageLoading'
import { descriptionBlock } from '@/content/about'

export default {
  name: 'AboutDescription',
  data() {
    return {
      imageUrl,
      content: descriptionBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.description
  margin: $desktopVerticalPaddings 0
  padding: 0 $desktopHorizontalPaddings
  border-top: 1px solid $tileBackground
  border-bottom: 1px solid $tileBackground
  @media (max-width: 1023px)
    margin: $mobileVerticalPaddings 0
    padding: 0 $mobileHorizontalPaddings

  &__wrapper
    border-left: 1px solid $tileBackground
    border-right: 1px solid $tileBackground
    & > *
      padding: 2.4rem
      @media (max-width: 1023px)
        padding: 1.6rem

  &__title
    border-bottom: 1px solid $tileBackground

  &__image
    border-bottom: 1px solid $tileBackground
    &-picture
      height: 42rem
      border-radius: 3rem
      background-position: center
      background-size: cover
      background-repeat: no-repeat

  &__content
    color: $textSecondary
    :deep(p)
      margin-bottom: 0.8rem
</style>
