<template>
  <section v-loading="isLoading">
    <page-header
      :title="content.title"
      :description="content.description"
      :image="content.image"
      :button="content.button"
      :mail-to="content.mailTo"
      v-on="$listeners"
    />
    <div ref="anchor" class="news__list">
      <news-item v-for="item in news" :key="item.id" :content="item" />
    </div>
  </section>
</template>

<script>
import Api from '@/api'
import PageHeader from '@/components/PageHeader.vue'
import NewsItem from '@/views/NewsPage/components/NewsItem.vue'

import { headerBlock } from '@/content/news'
import offsetMixin from '@/mixins/offsetMixin'
export default {
  name: 'NewsPage',
  metaInfo: {
    title: 'Новости',
    meta: [
      {
        name: 'description',
        content:
          'Следите за новостями Новороссийского автотерминала, чтобы быть в курсе актуальных новинок и акций.',
      },
      {
        name: 'keywords',
        content:
          'новороссийский автотерминал, парковка новороссийск, парковки новороссийска, грузовое СТО новороссийск, hongyan, хонгян, хонгянг, hongyan каталог, юг агро 2023, выставка юг агро 2023, юг агро краснодар 2023',
      },
    ],
  },
  components: { PageHeader, NewsItem },
  mixins: [offsetMixin],
  data() {
    return {
      isLoading: false,
      content: headerBlock,
      news: [],
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      let { data } = await Api.getNewsPage()

      this.news = data.map(item => {
        let content = item.content.split(/<p data-f-id=/)[0]

        return {
          title: item.title,
          content,
          image: item.image,
        }
      })
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<style lang="sass" scoped>
.news__list
  display: flex
  flex-direction: column
  gap: 6rem
  padding: 100px 0
  @media (max-width: 1023px)
    gap: 3rem
    padding: $mobileVerticalPaddings 0
</style>
