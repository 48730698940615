<template>
  <div v-loading="isLoading">
    <page-header
      :title="content.title"
      :description="content.description"
      :image="content.image"
      :button="content.button"
      :video-url="content.videoUrl"
      :mail-to="content.mailTo"
      v-on="$listeners"
    />
    <div ref="anchor">
      <div v-if="trucks" class="trucks">
        <catalog-item v-for="item in trucks" :key="item.id" :truck="item" />
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api'
import PageHeader from '@/components/PageHeader.vue'
import CatalogItem from '@/views/CatalogPage/components/CatalogItem.vue'
import { headerBlock } from '@/content/catalog'
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'CatalogPage',
  metaInfo: {
    title: 'Продажа техники HONGYAN в Новороссийске',
    titleTemplate: '%s',
    meta: [
      {
        name: 'description',
        content:
          'Официальный дистрибьютор Hongyan в России. Полный спектр грузовых автомобилей по европейским технологиям: самосвалы, шасси, автобетоносмесители и тягачи.',
      },
      {
        name: 'keywords',
        content:
          'saic iveco, hongyan, хонгян, хонгянг, hongyan каталог, каталог хонг янг, каталог хонгян',
      },
    ],
  },
  components: { PageHeader, CatalogItem },
  mixins: [offsetMixin],
  data() {
    return {
      isLoading: false,
      trucks: null,
      content: headerBlock,
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      let { data } = await Api.getTrucks()

      this.trucks = data
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<style lang="sass" scoped>
.trucks
  display: flex
  flex-direction: column
  gap: 6rem
  padding: $desktopVerticalPaddings 0
  @media (max-width: 1023px)
    gap: 3rem
    padding: $mobileVerticalPaddings 0
</style>
