<template>
  <div>
    <div :class="['input', inputStatus, { error: hasError }]" @click="start">
      <div :class="['input__content', inputStatus, { error: hasError }]">
        <div :class="['input__content-prompt', inputStatus]">
          {{ title }}
        </div>
        <el-input
          ref="input"
          v-model="inputContent"
          type="textarea"
          rows="3"
          resize="none"
          maxlength="200"
          show-word-limit
          :class="{ error: hasError }"
          @blur="inputChanged"
        />
      </div>
    </div>
    <transition>
      <div v-if="hasError" class="input__error">
        <div class="input__error-image">
          <img src="@/assets/img/new-error.svg" alt="phone" />
        </div>
        <p class="input__error-text">{{ errorText }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DoubleTextarea',
  props: {
    title: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputContent: '',
      inputStatus: '',
    }
  },
  methods: {
    start() {
      this.inputStatus = 'active'
      this.$refs.input.focus()
      this.$emit('input-focus', this.inputContent)
    },
    inputChanged() {
      if (!this.inputContent.length) {
        this.inputStatus = ''
      }
      this.$emit('input-changed', this.inputContent)
    },
  },
}
</script>

<style lang="sass" scoped>
.input
  display: flex
  width: 100%
  margin-bottom: 2rem
  border-radius: 1rem
  background: #F6F8F8
  cursor: pointer

  &.error
    margin-bottom: 0
    border: 1px solid $textError

  &__content
    flex: 1
    display: flex
    flex-direction: column
    justify-content: center
    padding: 0.6rem 1.2rem
    border-radius: 1rem
    &.error
      background: #FCECEF

    .el-textarea
      width: 100%

      ::v-deep .el-textarea__inner
        padding: 0
        font-family: $secondaryFont
        font-weight: 400
        font-size: 1.4rem
        border: none
        background: #F6F8F8
        color: $textPrimary

      &.error
        ::v-deep .el-textarea__inner
          background: #FCECEF

      ::v-deep .el-input__count
        position: absolute
        bottom: -24px
        right: -8px
        font-family: $secondaryFont
        font-size: 12px
        color: $textSecondary

    &-prompt
      height: 1.4rem
      margin-bottom: 4px
      font-family: $secondaryFont
      font-weight: 400
      font-size: 1.4rem
      color: $textSecondary
      transition: all .3s ease
      &.active
        font-size: 1.2rem

  &__error
    display: flex
    align-items: center
    height: 2rem
    font-family: $secondaryFont
    font-size: 1.2rem
    line-height: 1.6rem
    color: $textError
    &-image
      margin-top: 2px
      margin-right: 2px
</style>
