<template>
  <section class="video">
    <div class="video__title font-title">{{ content.title }}</div>
    <div class="video__wrapper">
      <youtube ref="youtube" :video-id="content.videoId" :fit-parent="true" />
    </div>
  </section>
</template>

<script>
import { videoBlock } from '@/content/about'

export default {
  name: 'AboutVideo',
  data() {
    return {
      content: videoBlock,
    }
  },
}
</script>

<style lang="sass" scoped>
.video
  position: relative
  padding: 0 $desktopHorizontalPaddings $desktopVerticalPaddings
  @media (max-width: 1023px)
    padding: 0 $mobileHorizontalPaddings $mobileVerticalPaddings

  &__title
    margin-bottom: 6rem
    @media (max-width: 1023px)
      margin-bottom: 3rem
</style>
