<template>
  <section class="requisites">
    <h2 class="requisites__title font-title">{{ content.title }}</h2>
    <div
      v-for="(chapter, index) in content.list"
      :key="index"
      class="requisites__chapter"
    >
      <div
        v-for="(item, index2) in chapter"
        :key="index2"
        class="requisites__item font2-18"
      >
        <div class="requisites__item-label">{{ item.label }}</div>
        <div class="requisites__item-value">{{ item.value }}</div>
      </div>
    </div>
    <a href="/OOO_NAT.docx" target="_blank" class="main-button">
      <img :src="imageUrl(content.button.icon)" alt="header" class="icon" />
      <span>{{ content.button.title }}</span>
    </a>
  </section>
</template>

<script>
import { requisitesBlock } from '@/content/contacts'
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'ContactsRequisites',
  data() {
    return {
      content: requisitesBlock,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.requisites
  padding: 100px $desktopHorizontalPaddings $desktopVerticalPaddings
  @media (max-width: 1023px)
    padding: $mobileVerticalPaddings $mobileHorizontalPaddings

  &__title
    margin-bottom: 6rem
    @media (max-width: 1023px)
      margin-bottom: 3rem

  &__chapter
    margin-bottom: 4rem
    @media (max-width: 1023px)
      margin-bottom: 3rem

  &__item
    display: flex
    padding: 1.4rem 0
    border-bottom: 1px solid $tileBackground
    @media (max-width: 1023px)
      flex-direction: column
      padding: 1.2rem 0

    &-label
      flex-shrink: 0
      width: 32rem
      margin-right: 6rem
      font-weight: 600
      @media (max-width: 1023px)
        width: 100%
        margin-bottom: 0.8rem

  .main-button
    display: flex
    align-items: center
    margin-top: 4rem
    text-decoration: none
    @media (max-width: 1023px)
      margin-top: 2.4rem
    .icon
      margin-right: 0.8rem
</style>
