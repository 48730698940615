<template>
  <swiper :options="swiperOptions" class="truck__gallery-swiper">
    <swiper-slide
      v-for="action in content"
      :key="action.id"
      class="swiper__slide"
    >
      <section class="action">
        <div class="left-line mod-big" />
        <div class="left-line mod-middle" />
        <div class="left-line mod-small" />
        <div class="right-line mod-big" />
        <div class="right-line mod-middle" />
        <div class="right-line mod-small" />
        <div class="action__block mod-content">
          <div class="font2-18">{{ action.title }}</div>
          <div class="content__description font-subtitle">
            {{ action.description }}
          </div>
          <div class="font2-18">{{ action.terms }}</div>
        </div>
        <div class="action__block mod-image">
          <div
            :style="{
              'background-image': 'url(' + imageUrl(action.image) + ')',
            }"
            class="action__block-image"
          />
        </div>
      </section>
    </swiper-slide>
    <div slot="button-prev" class="swiper-button-prev" />
    <div slot="button-next" class="swiper-button-next" />
    <div slot="pagination" class="swiper-pagination" />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { actionBlock } from '@/content/home'
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'HomeActions',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      content: actionBlock,
      imageUrl,
      swiperOptions: {
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
    }
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/styles/swiper'
.action
  position: relative
  display: flex
  background: $textPrimary
  padding: 0 $desktopHorizontalPaddings
  @media (max-width: 1023px)
    flex-direction: column
    padding: 0 $mobileHorizontalPaddings

  &__block
    position: relative
    width: 50%
    @media (max-width: 1023px)
      width: 100%

    &.mod-image
      padding: 2.4rem 0 2.4rem 1rem
      @media (max-width: 1023px)
        padding: 0 0 4rem

    &.mod-content
      display: flex
      flex-direction: column
      justify-content: center
      padding-right: 7rem
      color: $backgroundGray
      @media (max-width: 1023px)
        padding: 4rem 0 3rem
      .content__description
        margin: 2rem 0
        color: $textWhite
        @media (max-width: 1023px)
          margin: 1.6rem 0

    &-image
      height: 40.2rem
      border-radius: 30px
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      @media (max-width: 1023px)
        height: 33.5rem

  // fucking lines block ======================================================
  .left-line
    position: absolute
    left: 0
    bottom: 0
    border-top: 1px solid $textSecondary
    border-right: 1px solid $textSecondary
    border-radius: 0 67% 0 0
    background: transparent
    @media (max-width: 1023px)
      border-radius: 0 80% 0 0

    &.mod-small
      width: 13.9rem
      height: 11.6rem
      @media (max-width: 1023px)
        width: 7rem
        height: 5.8rem

    &.mod-middle
      width: 19.9rem
      height: 17.6rem
      @media (max-width: 1023px)
        width: 10rem
        height: 8.8rem

    &.mod-big
      width: 25.9rem
      height: 23.6rem
      @media (max-width: 1023px)
        width: 13rem
        height: 11.8rem

  .right-line
    position: absolute
    right: 0
    top: 0
    border-left: 1px solid $textSecondary
    border-bottom: 1px solid $textSecondary
    border-radius: 0 0 0 50px
    background: transparent

    &.mod-small
      width: 97rem
      height: 11.6rem
      border-radius: 0 0 0 5rem
      @media (max-width: 1023px)
        width: 11.7rem
        height: 5.8rem

    &.mod-middle
      width: 103rem
      height: 17.6rem
      border-radius: 0 0 0 11rem
      @media (max-width: 1023px)
        width: 14.7rem
        height: 8.8rem
        border-radius: 0 0 0 8rem

    &.mod-big
      width: 109rem
      height: 23.6rem
      border-radius: 0 0 0 17rem
      @media (max-width: 1023px)
        width: 17.7rem
        height: 11.8rem
        border-radius: 0 0 0 11rem
  // ===================================================

.swiper-button-prev
  left: calc(18.75vw - 6.6rem)
  transform: none

.swiper-button-next
  right: calc(18.75vw - 6.6rem)
  transform: none
</style>
