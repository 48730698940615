import { mailTo } from '@/content/emailsList'

export const headerBlock = {
  image: 'catalog-header.png',
  title: 'Наш модельный ряд содержит только проверенные временем автомобили',
  description:
    'Для получения подробной консультации заполните форму или свяжитесь с нашим отделом продаж&nbsp;<span>+7&nbsp;988&nbsp;669-36-38</span>',
  button: 'Узнать цену',
  videoUrl: 'https://youtu.be/G3-G_PgNN80',
  mailTo: mailTo.catalog,
}

export const catalogItem = {
  equipment: 'Комплектация',
  properties: 'Характеристики',
  expandButton: 'Подробнее',
  collapseButton: 'Свернуть',
  downloadButton: 'Скачать технические показатели',
  downloadIcon: 'download.svg',
  watchButton: 'Посмотреть видео',
  watchIcon: 'watch.svg',
}
