// данные для самой формы
export const formData = {
  blockTitle: 'Задать вопрос или оставить отзыв по работе, руководству',
  modalTitle: 'Задайте вопрос или оставьте отзыв по работе',
  description: 'Менеджер свяжется с вами в ближайшее время!',
  image: 'home/home-form.png',
  placeholders: {
    name: 'Ваше имя',
    email: 'Ваша почта',
    question: 'Ваш вопрос или отзыв',
  },
  button: 'Отправить',
  checkbox: {
    text: 'Я согласен с ',
    link: 'политикой конфиденциальности',
    url: '/policy.pdf',
  },
}

export const modalRequestBlock = {
  success: {
    title: 'Спасибо, ваша заявка отправлена!',
    description: 'В ближайшее время мы с вами свяжемся!',
  },
  error: {
    title: 'Возникли технические проблемы!',
    description: 'Попробуйте отправить заявку еще раз',
  },
  button: 'Вернуться на сайт',
}
