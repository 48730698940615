<template>
  <section class="news">
    <div class="news__list">
      <news-item v-for="item in news" :key="item.id" :content="item" />
    </div>
  </section>
</template>

<script>
import NewsItem from '@/views/NewsPage/components/NewsItem.vue'

export default {
  name: 'HomeNews',
  components: { NewsItem },
  props: {
    news: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="sass" scoped>
.news
  padding: $desktopVerticalPaddings 0
  @media (max-width: 1023px)
    padding: $mobileVerticalPaddings 0

  &__list
    display: flex
    flex-direction: column
    gap: 6rem
    @media (max-width: 1023px)
      gap: 3rem
</style>
