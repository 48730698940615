import HomePage from '@/views/HomePage/HomePage'
import AboutPage from '@/views/AboutPage/AboutPage.vue'
import CatalogPage from '@/views/CatalogPage/CatalogPage.vue'
import ContactsPage from '@/views/ContactsPage/ContactsPage.vue'
import ServicesPage from '@/views/ServicesPage/ServicesPage.vue'
import NewsPage from '@/views/NewsPage/NewsPage.vue'
import StoPage from '@/views/StoPage/StoPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage,
  },
  {
    path: '/services',
    name: 'Services',
    component: ServicesPage,
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: CatalogPage,
  },
  {
    path: '/truck-sto',
    name: 'TruckSTO',
    component: StoPage,
  },
  {
    path: '/news',
    name: 'News',
    component: NewsPage,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactsPage,
  },
]

export default routes
