import { mailTo } from '@/content/emailsList'

export const headerBlock = {
  image: 'news/news-header.jpg',
  title: 'Свежие новости и актуальные события из жизни нашей компании',
  description:
    'Если у вас имеются вопросы и предложения&nbsp;- заполните форму или свяжитесь с нами по телефону&nbsp;<span>+7&nbsp;988&nbsp;669-37-27</span>',
  button: 'Задать вопрос',
  mailTo: mailTo.news,
}
