<template>
  <section class="form">
    <two-side-component :image="content.image">
      <div class="form__title font-subtitle">{{ content.blockTitle }}</div>
      <lead-form :mail-to="mailTo" v-on="$listeners" />
    </two-side-component>
  </section>
</template>

<script>
import TwoSideComponent from '@/components/TwoSideComponent.vue'
import LeadForm from '@/components/common/Form/LeadForm'
import { imageUrl } from '@/helpers/imageLoading'
import { formData } from '@/content/modals'
import { headerBlock } from '@/content/home'

export default {
  name: 'HomeForm',
  components: { TwoSideComponent, LeadForm },
  data() {
    return {
      content: formData,
      mailTo: headerBlock.mailTo,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.form
  margin-bottom: $desktopVerticalPaddings
  @media (max-width: 1023px)
    margin-bottom: $mobileVerticalPaddings

  &__title
    margin-bottom: 3.2rem
    @media (max-width: 1919px)
      margin-bottom: 2.4rem
</style>
