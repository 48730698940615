<template>
  <div class="video" @click="$emit('close')">
    <div class="video__close" @click="$emit('close')">
      <img :src="imageUrl('menu-close.svg')" alt="close" />
    </div>
    <div class="video__play">
      <youtube ref="youtube" :video-id="videoId" :fit-parent="true" />
    </div>
  </div>
</template>

<script>
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'ModalWatchVideo',
  props: {
    videoUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageUrl,
    }
  },
  computed: {
    videoId() {
      return this.videoUrl ? this.videoUrl.split('/').toReversed()[0] : ''
    },
  },
}
</script>

<style lang="sass" scoped>
.video
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  background: $modalLayout
  z-index: 100
  &__close
    position: absolute
    top: 3.4rem
    right: 3.4rem
    width: 1.6rem
    height: 1.6rem
    cursor: pointer
    @media(max-width: 1023px)
      width: 2.4rem
      height: 2.4rem
    img
      width: 100%
      height: 100%
  &__play
    position: absolute
    width: 128rem
    left: 50%
    transform: translateX(-50%)
    @media(max-width: 1023px)
      width: calc(100% - 5rem)
</style>
