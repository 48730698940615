<template>
  <section class="truck">
    <div class="title">
      <div class="title__detail">
        <h2 class="title__detail-name font-subtitle">{{ truck.name }}</h2>
        <div class="title__detail-model font2-18">{{ truck.model }}</div>
      </div>
      <div v-if="truck.subtitle" class="title__subtitle font2-18">
        {{ truck.subtitle }}
      </div>
    </div>

    <div v-if="truck.images && truck.images.length" class="truck__gallery">
      <swiper :options="swiperOptions" class="truck__gallery-swiper">
        <swiper-slide
          v-for="image in truck.images"
          :key="image.id"
          :style="{
            'background-image': `url(${image.file_url})`,
          }"
          class="swiper__slide"
        />
        <div slot="button-prev" class="swiper-button-prev" />
        <div slot="button-next" class="swiper-button-next" />
        <div slot="pagination" class="swiper-pagination" />
      </swiper>
    </div>
    <div class="truck__trigger">
      <div class="truck__trigger-title">{{ content.properties }}</div>
      <div class="main-button" @click="isSpecsOpen = !isSpecsOpen">
        {{ isSpecsOpen ? content.collapseButton : content.expandButton }}
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="isSpecsOpen">
        <div class="truck__specs">
          <div
            v-for="(item, index) in truckSpecs"
            :key="index"
            class="specs__item font2-18"
          >
            <div class="specs__item-title">{{ item.name }}</div>
            <div class="specs__item-value">{{ item.value }}</div>
          </div>
        </div>
        <div
          v-if="truck.summary || canShowButtons"
          class="truck__summary font2-18"
        >
          <div v-if="truck.summary" class="truck__summary-title">
            {{ content.equipment }}
          </div>
          <div v-if="truck.summary" class="truck__summary-description">
            {{ truck.summary }}
          </div>
          <div v-if="canShowButtons" class="truck__summary-buttons">
            <div
              v-if="truck.videoLink"
              class="main-button mod-icon"
              @click="showVideo = true"
            >
              <img
                :src="imageUrl(content.watchIcon)"
                alt="header"
                class="icon"
              />
              <span>{{ content.watchButton }}</span>
            </div>
            <a
              v-if="truck.documents.length"
              :href="truck.documents[0].file_url"
              target="_blank"
              class="main-button mod-icon"
            >
              <img
                :src="imageUrl(content.downloadIcon)"
                alt="header"
                class="icon"
              />
              <span>{{ content.downloadButton }}</span>
            </a>
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <ModalWatchVideo
      v-if="showVideo"
      :video-url="truck.videoLink"
      @close="showVideo = false"
    />
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { catalogItem } from '@/content/catalog'
import { imageUrl } from '@/helpers/imageLoading'
import ModalWatchVideo from '@/components/common/Modals/ModalWatchVideo.vue'

export default {
  name: 'CatalogItem',
  components: { Swiper, SwiperSlide, ModalWatchVideo },
  props: {
    truck: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      content: catalogItem,
      isMobile: false,
      isSpecsOpen: false,
      showVideo: false,
      swiperOptions: {
        spaceBetween: 20,
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
        pagination: {
          el: '.swiper-pagination',
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1600: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
      },
      imageUrl,
    }
  },
  computed: {
    truckSpecs() {
      // для мобилы список оставляем без изменений
      if (this.isMobile) {
        return this.truck.specs
      }
      // для десктопа переформатируем под две колонки
      let rows = Math.ceil(this.truck.specs.length / 2)
      let result = []
      let left = this.truck.specs.slice(0, rows)
      let right = this.truck.specs.slice(rows)

      for (let i = 0; i < left.length; i++) {
        result[i * 2] = left[i]
        result[i * 2 + 1] = right[i] || {}
      }

      return result
    },
    canShowButtons() {
      return this.truck.videoLink || this.truck.documents.length
    },
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1024
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/styles/swiper'
.truck
  border-top: 1px solid $tileBackground
  border-bottom: 1px solid $tileBackground
  padding: 0 $desktopHorizontalPaddings
  @media (max-width: 1023px)
    padding: 0 $mobileHorizontalPaddings

  .title
    padding: 2.4rem
    border-left: 1px solid $tileBackground
    border-right: 1px solid $tileBackground
    border-bottom: 1px solid $tileBackground
    @media (max-width: 1023px)
      flex-direction: column
      align-items: flex-start
      padding: 1rem 1.6rem
    &__detail
      display: flex
      justify-content: space-between
      align-items: center
      @media (max-width: 1023px)
        flex-direction: column
        align-items: flex-start
      &-name
        margin-right: 2rem
        @media (max-width: 1023px)
          margin-right: 0
          margin-bottom: 0.4rem
      &-model
        flex-shrink: 0
        color: $textSecondary
    &__subtitle
      margin-top: 1rem
      color: $textError

  &__gallery
    height: 45rem
    padding: 2.4rem 2.4rem 0
    border-left: 1px solid $tileBackground
    border-right: 1px solid $tileBackground
    @media (max-width: 1023px)
      height: 35.3rem
      padding: 1.6rem 1.6rem 0

    .truck__gallery-swiper
      height: 42.6rem
      @media (max-width: 1023px)
        height: 33.7rem
      .swiper__slide
        height: 36.8rem
        background-position: center
        background-repeat: no-repeat
        background-size: cover
        border-radius: 20px
        @media (max-width: 1023px)
          height: 29.5rem

    .swiper-container-horizontal > .swiper-pagination-bullets
      bottom: 2.6rem
      @media (max-width: 1023px)
        bottom: 1.7rem

  &__trigger
    display: flex
    justify-content: space-between
    align-items: center
    height: 8rem
    padding: 0 2.4rem
    border-left: 1px solid $tileBackground
    border-right: 1px solid $tileBackground
    border-top: 1px solid $tileBackground
    @media (max-width: 1023px)
      height: 6rem
      padding: 0 1.6rem
    &-title
      font-size: 2rem
      line-height: 2.4rem
      font-weight: 500
      letter-spacing: -0.005em
      @media (max-width: 1023px)
        font-size: 1.6rem
        line-height: 2rem
    .main-button
      width: 15rem
      padding: 0.6rem 0
      @media (max-width: 1023px)
        width: 10.6rem
        font-size: 1.4rem
        line-height: 1.4rem
        padding: 0.7rem 0

  &__specs
    display: flex
    flex-wrap: wrap
    border-right: 1px solid $tileBackground

    .specs__item
      width: 50%
      display: flex
      justify-content: space-between
      align-items: center
      padding: 2rem 2.4rem
      border-left: 1px solid $tileBackground
      border-top: 1px solid $tileBackground
      @media (max-width: 1023px)
        width: 100%
        padding: 1rem 1.6rem
      &-title
        width: 67%
        font-weight: 600
      &-value
        flex: 1
        text-align: right
        color: $textSecondary

  &__summary
    padding: 2.4rem 2.4rem 4.1rem
    border-top: 1px solid $tileBackground
    border-left: 1px solid $tileBackground
    border-right: 1px solid $tileBackground
    @media (max-width: 1023px)
      padding: 1.6rem 1.6rem 2rem
    &-title
      margin-bottom: 1.6rem
      font-weight: 600
      @media (max-width: 1023px)
        margin-bottom: 0.8rem
    &-description
      margin-bottom: 2.4rem
      color: $textSecondary

    &-buttons
      display: flex
      gap: 1.6rem
      @media (max-width: 1023px)
        flex-direction: column
</style>
