<template>
  <section class="sto">
    <swiper :options="swiperOptions" class="sto__swiper">
      <swiper-slide
        v-for="image in content"
        :key="image.id"
        class="sto__swiper-slide"
      >
        <div
          :style="{
            'background-image': 'url(' + imageUrl(image.image) + ')',
          }"
          class="slide__image"
        />
        <div class="font2-18">{{ image.title }}</div>
      </swiper-slide>
      <div slot="button-prev" class="swiper-button-prev" />
      <div slot="button-next" class="swiper-button-next" />
      <div slot="pagination" class="swiper-pagination" />
    </swiper>
  </section>
</template>

<script>
import { sliderBlock } from '@/content/sto'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'StoSlider',
  components: { Swiper, SwiperSlide },
  data() {
    return {
      content: sliderBlock,
      imageUrl,
      swiperOptions: {
        spaceBetween: 20,
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
        pagination: {
          el: '.swiper-pagination',
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1600: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
      },
    }
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/styles/swiper'
.sto
  margin-top: $desktopVerticalPaddings
  padding: 0 $desktopHorizontalPaddings
  @media (max-width: 1099px)
    margin-top: $mobileVerticalPaddings
    padding: 0 $mobileHorizontalPaddings

  &__swiper
    height: 48.4rem
    @media (max-width: 1099px)
      height: 39.5rem

    .slide__image
      height: 38.6rem
      margin-bottom: 1.6rem
      background-position: center
      background-size: cover
      border-radius: 2rem
      @media (max-width: 1099px)
        height: 33.5rem

    .swiper-button-prev, .swiper-button-next
      transform: translateY(calc(-50% - 2.5rem))
</style>
