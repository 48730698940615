<template>
  <section v-if="prices" class="container">
    <div class="prices">
      <div class="prices__title" @click="isStoOpen = !isStoOpen">
        <div class="font-section">{{ prices.sto.title }}</div>
        <div class="prices__title-arrow">
          <img
            src="@/assets/img/angle-down.svg"
            :class="['picture', { opened: isStoOpen }]"
            alt="angle"
          />
        </div>
      </div>
      <el-collapse-transition>
        <div v-show="isStoOpen" class="prices__content">
          <price-list
            :layout="content"
            :subtitle="prices.sto.subtitle"
            :list="prices.sto.items"
          />
        </div>
      </el-collapse-transition>
    </div>

    <div class="prices">
      <div class="prices__title" @click="isStoSalesOpen = !isStoSalesOpen">
        <div class="font-section">{{ prices.sto_sale.title }}</div>
        <div class="prices__title-arrow">
          <img
            src="@/assets/img/angle-down.svg"
            :class="['picture', { opened: isStoSalesOpen }]"
            alt="angle"
          />
        </div>
      </div>
      <el-collapse-transition>
        <div v-show="isStoSalesOpen" class="prices__content">
          <div
            v-for="(value, key) in prices.sto_sale.items"
            :key="key"
            class="prices__content-wrapper"
          >
            <div
              v-if="value.items.length"
              class="prices__content-title font-section"
            >
              {{ value.title }}
            </div>
            <price-list
              :layout="content"
              :subtitle="value.subtitle"
              :list="value.items"
            />
          </div>
        </div>
      </el-collapse-transition>
    </div>

    <div class="buttons">
      <a
        :href="prices.links.download_url"
        target="_blank"
        class="main-button mod-icon"
      >
        <img :src="imageUrl(content.downloadIcon)" alt="header" class="icon" />
        <span>{{ content.downloadButton }}</span>
      </a>
      <div
        v-if="prices.links.video_url"
        class="main-button mod-icon"
        @click="showVideo = true"
      >
        <img :src="imageUrl(content.watchIcon)" alt="header" class="icon" />
        <span>{{ content.watchButton }}</span>
      </div>
    </div>
    <ModalWatchVideo
      v-if="showVideo"
      :video-url="prices.links.video_url"
      @close="showVideo = false"
    />
  </section>
</template>

<script>
import { pricesBlock } from '@/content/sto'
import { imageUrl } from '@/helpers/imageLoading'
import PriceList from '@/components/PriceList.vue'
import ModalWatchVideo from '@/components/common/Modals/ModalWatchVideo.vue'

export default {
  name: 'StoPrices',
  components: { PriceList, ModalWatchVideo },
  props: {
    prices: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isStoOpen: false,
      isStoSalesOpen: false,
      showVideo: false,
      content: pricesBlock,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.container
  margin-top: $desktopVerticalPaddings
  padding: 0 $desktopHorizontalPaddings
  @media (max-width: 1099px)
    margin-top: $mobileVerticalPaddings
    padding: 0 $mobileHorizontalPaddings

.prices
  margin-bottom: 0.8rem
  background: $textLightWhite
  border-radius: 8px

  &__title
    display: flex
    justify-content: space-between
    align-items: center
    padding: 2.5rem 2.4rem
    cursor: pointer
    @media (max-width: 1099px)
      padding: 1.6rem
    &-arrow
      flex-shrink: 0
      width: 2.4rem
      height: 2.4rem
      @media (max-width: 1099px)
        width: 1.6rem
        height: 1.6rem
      .picture
        width: 100%
        height: 100%
        transition: transform 0.3s ease
        &.opened
          transform: rotateX(180deg)
  &__content
    padding: 2rem 2.4rem 4.8rem
    border-radius: 0 0 8px 8px
    @media (max-width: 1099px)
      padding: 1.6rem 1.6rem 2.4rem
    &-title
      margin-bottom: 3.2rem
      @media (max-width: 1099px)
        margin-bottom: 1.6rem
    &-wrapper:first-child
      margin-bottom: 6rem
      @media (max-width: 1099px)
        margin-bottom: 4rem

.buttons
  display: flex
  gap: 1.6rem
  margin-top: 4rem
  @media (max-width: 1023px)
    flex-direction: column
    margin-top: 2.4rem
</style>
