<template>
  <header class="header">
    <div class="header__main">
      <h1 v-html-safe="title" class="header__main-title" />

      <h3 v-html-safe="description" class="header__main-description font2-18" />

      <div class="header__main-buttons">
        <div class="main-button" @click="$emit('show-form', mailTo)">
          {{ button }}
        </div>
        <div
          v-if="videoUrl"
          class="main-button mod-icon"
          @click="showVideo = true"
        >
          <img :src="imageUrl('watch.svg')" alt="header" class="icon" />
          <span>Посмотреть видео</span>
        </div>
      </div>
    </div>
    <div class="header__picture">
      <div class="header__picture-wrapper">
        <img :src="imageUrl(image)" alt="header" />
      </div>
    </div>
    <ModalWatchVideo
      v-if="showVideo"
      :video-url="videoUrl"
      @close="showVideo = false"
    />
  </header>
</template>

<script>
import { imageUrl } from '@/helpers/imageLoading'
import ModalWatchVideo from '@/components/common/Modals/ModalWatchVideo.vue'

export default {
  name: 'PageHeader',
  components: { ModalWatchVideo },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    button: {
      type: String,
      default: '',
    },
    mailTo: {
      type: String,
      default: 'test@nat.ooo',
    },
    videoUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imageUrl,
      showVideo: false,
    }
  },
}
</script>

<style lang="sass" scoped>
.header
  display: flex
  height: 50rem
  background: $textPrimary
  @media (max-width: 1023px)
    flex-direction: column
    height: auto
  &__main
    width: 50%
    padding: 9rem 17rem 0
    @media (max-width: 1919px) and (min-width: 1440px)
      padding: 12rem 11rem 0
    @media (max-width: 1439px) and (min-width: 1024px)
      padding: 12rem 9rem 0
    @media (max-width: 1023px)
      width: 100%
      padding: 4rem 2rem 5rem
    &-title
      margin-bottom: 20px
      font-weight: 500
      font-size: 4rem
      line-height: 4.8rem
      color: $textWhite
      letter-spacing: -0.005em
      @media (max-width: 1919px) and (min-width: 1440px)
        font-size: 3.2rem
        line-height: 3.8rem
      @media (max-width: 1439px) and (min-width: 1024px)
        font-size: 3rem
        line-height: 3.6rem
      @media (max-width: 1023px)
        margin-bottom: 14px
        font-size: 2.4rem
        line-height: 3rem
    &-description
      margin-bottom: 44px
      color: $backgroundGray
      @media (max-width: 1279px)
        margin-bottom: 32px
      :deep(span)
        color: $buttonPrimary

    &-buttons
      display: flex
      gap: 2.4rem
      @media (max-width: 1023px)
        flex-direction: column
        gap: 1.6rem

  &__picture
    width: 50%
    @media (max-width: 1023px)
      width: 100%
      height: 20rem

    &-wrapper
      height: 100%
      img
        width: 100%
        height: 100%
        object-fit: cover
</style>
