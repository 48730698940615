<template>
  <div v-loading="isLoading">
    <page-header
      :title="content.title"
      :description="content.description"
      :image="content.image"
      :button="content.button"
      :mail-to="content.mailTo"
      v-on="$listeners"
    />
    <div ref="anchor">
      <contacts-timetable />
    </div>
    <contacts-phones v-if="contacts" :contacts="contacts" />
    <contacts-requisites />
    <home-form v-on="$listeners" />
  </div>
</template>

<script>
import Api from '@/api'
import PageHeader from '@/components/PageHeader.vue'
import ContactsTimetable from '@/views/ContactsPage/components/ContactsTimetable.vue'
import ContactsPhones from '@/views/ContactsPage/components/ContactsPhones.vue'
import ContactsRequisites from '@/views/ContactsPage/components/ContactsRequisites.vue'
import HomeForm from '@/views/HomePage/components/HomeForm.vue'
import { headerBlock } from '@/content/contacts'
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'CatalogPage',
  metaInfo: {
    title: 'Контакты',
    meta: [
      {
        name: 'description',
        content:
          'Мы находимся по адресу г.о. Новороссийск, с. Владимировка, тер. Автодорога «Новороссийск-Керченский пролив», км. 10-й, д.7. Свяжитесь с нами по телефону или с помощью формы обратной связи на странице.',
      },
      {
        name: 'keywords',
        content:
          'новороссийский автотерминал, парковка новороссийск, парковки новороссийска, грузовое СТО новороссийск',
      },
    ],
  },
  components: {
    PageHeader,
    ContactsTimetable,
    ContactsPhones,
    ContactsRequisites,
    HomeForm,
  },
  mixins: [offsetMixin],
  data() {
    return {
      content: headerBlock,
      isLoading: false,
      contacts: null,
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      let { data } = await Api.getContacts()

      this.contacts = data
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  },
}
</script>
