<template>
  <section v-loading="isLoading">
    <page-header
      :title="content.title"
      :description="content.description"
      :image="content.image"
      :button="content.button"
      :mail-to="content.mailTo"
      v-on="$listeners"
    />
    <div ref="anchor">
      <sto-trademarks />
    </div>
    <sto-prices :prices="priceList" />
    <sto-slider />
    <sto-timetable />
  </section>
</template>

<script>
import Api from '@/api'
import PageHeader from '@/components/PageHeader.vue'
import StoPrices from '@/views/StoPage/components/StoPrices.vue'
import StoSlider from '@/views/StoPage/components/StoSlider.vue'
import StoTrademarks from '@/views/StoPage/components/StoTrademarks.vue'
import StoTimetable from '@/views/StoPage/components/StoTimetable.vue'
import { headerBlock } from '@/content/sto'
import offsetMixin from '@/mixins/offsetMixin'
export default {
  name: 'StoPage',
  metaInfo: {
    title:
      'СТО, запчасти и смазочные материалы для грузового транспорта в Новороссийске',
    titleTemplate: '%s',
    meta: [
      {
        name: 'description',
        content:
          'Современный автосервис для грузового автотранспорта с широким спектром услуг. Выгодные цены. Ремонт без навязанных услуг. Оказываем услуги по обслуживанию и ремонту грузовых автомобилей: Volvo Trucks, Renault Trucks, Hongyan, и многих других.',
      },
      {
        name: 'keywords',
        content:
          'автосервис, техцентр, автотехцентр, автомастерская, сервис автомобилей, станция техобслуживания, ремонт автомобилей, сайты сто грузовых новороссийск, грузовое СТО новороссийск, грузовая техника VOLVO, грузовая техника RENAULT TRUCKS, грузовая техника HONGYAN, грузовая техника SHACMAN, грузовая техника HOWO, грузовая техника FOTON, грузовая техника FAW, грузовая техника SITRAK, грузовая техника DONGFENG',
      },
    ],
  },
  components: {
    PageHeader,
    StoPrices,
    StoSlider,
    StoTrademarks,
    StoTimetable,
  },
  mixins: [offsetMixin],
  data() {
    return {
      content: headerBlock,
      isLoading: false,
      priceList: null,
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      let { data } = await Api.getStoPage()

      this.priceList = data
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<style lang="sass" scoped>
.development
  display: flex
  justify-content: center
  align-items: center
  height: 50vh
  font-size: 4rem
</style>
