import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    firstLightComponentOffset: 0,
  },
  getters: {
    getOffset(state) {
      return state.firstLightComponentOffset
    },
  },
  mutations: {
    SET_OFFSET(state, value) {
      state.firstLightComponentOffset = value
    },
  },
  actions: {
    setOffset({ commit }, offset) {
      commit('SET_OFFSET', offset)
    },
  },
})
