<template>
  <section class="landing">
    <home-header v-on="$listeners" />
    <div ref="anchor">
      <home-news :news="news" />
    </div>
    <home-actions />
    <home-services />
    <home-form v-on="$listeners" />
  </section>
</template>

<script>
import Api from '@/api'
import HomeHeader from './components/HomeHeader.vue'
import HomeNews from '@/views/HomePage/components/HomeNews.vue'
import HomeActions from '@/views/HomePage/components/HomeActions.vue'
import HomeServices from './components/HomeServices'
import HomeForm from './components/HomeForm'
import offsetMixin from '@/mixins/offsetMixin'

export default {
  name: 'HomePage',
  metaInfo: {
    title: 'Торгово-сервисная компания грузовой техники',
    meta: [
      {
        name: 'description',
        content:
          'Оказываем услуги грузовому транспорту на территории Новороссийска с 2008 года. Грузовое СТО, парковка и продажа техники в одном месте.',
      },
      {
        name: 'keywords',
        content:
          'новороссийский автотерминал, парковка новороссийск, парковки новороссийска, грузовое СТО новороссийск',
      },
    ],
  },
  components: {
    HomeHeader,
    HomeNews,
    HomeActions,
    HomeServices,
    HomeForm,
  },
  mixins: [offsetMixin],
  data() {
    return {
      isLoading: false,
      news: [],
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      let { data } = await Api.getNewsForMainPage()

      this.news = data.map(item => {
        let content = item.content.split(/<p data-f-id=/)[0]

        return {
          title: item.title,
          content,
          image: item.image,
        }
      })
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  },
}
</script>
