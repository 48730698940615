<template>
  <section>
    <two-side-component :image="content.image" :is-from-back="true">
      <div class="content font2-18">
        <div>
          <h2 class="content__title font-subtitle">
            {{ content.title }}
          </h2>
          <div v-html-safe="content.content" />
        </div>
        <navigation-button
          v-if="$route.name === 'Home'"
          text="Все новости"
          class="content__button"
          @clicked="$router.push({ name: 'News' })"
        />
      </div>
    </two-side-component>
  </section>
</template>

<script>
import { imageUrl } from '@/helpers/imageLoading'
import TwoSideComponent from '@/components/TwoSideComponent.vue'
import NavigationButton from '@/components/common/NavigationButton.vue'

export default {
  name: 'NewsItem',
  components: { TwoSideComponent, NavigationButton },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.content
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100%
  color: $textSecondary

  .content__title
    margin-bottom: 2rem
    color: $textPrimary
    @media (max-width: 1023px)
      margin-bottom: 1.6rem

  &__button
    align-self: flex-end
    margin-top: 4rem

  :deep(ul)
    margin-left: 3rem
    @media (max-width: 1023px)
      margin-left: 2rem
</style>
