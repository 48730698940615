<template>
  <section v-loading="isLoading">
    <page-header
      :title="content.title"
      :description="content.description"
      :image="content.image"
      :button="content.button"
      :mail-to="content.mailTo"
      v-on="$listeners"
    />

    <div ref="anchor">
      <services-list
        v-if="priceList"
        :list="priceList.terminal"
        :content="services"
        class="services"
      />
    </div>

    <services-list
      v-if="tiresList"
      :list="tiresList.tires"
      :content="tires"
      class="tires"
    />
  </section>
</template>

<script>
import Api from '@/api'
import PageHeader from '@/components/PageHeader.vue'
import ServicesList from './components/ServicesList.vue'
import offsetMixin from '@/mixins/offsetMixin'
import { headerBlock, servicesBlock, tiresBlock } from '@/content/services'

export default {
  name: 'ServicesPage',
  metaInfo: {
    title: 'Автостоянка грузового транспорта в Новороссийске',
    titleTemplate: '%s',
    meta: [
      {
        name: 'description',
        content:
          'Общая территория Новороссийского автотерминала составляет 25 га. Под организацию услуг автостоянки используется 17га. Вы можете воспользоваться усгалами погрузчика, эвакуатора, топливозаправщика. Стоимость услуг для грузового автотранспорта актуальна с 01.01.2024г.',
      },
      {
        name: 'keywords',
        content:
          'зерновоз новороссийск, парковка новороссийск, автотерминал новороссийск, парковка для грузовиков, парковка грузовиков с прицепом',
      },
    ],
  },
  components: { PageHeader, ServicesList },
  mixins: [offsetMixin],
  data() {
    return {
      isLoading: false,
      content: headerBlock,
      priceList: null,
      tiresList: null,
      services: servicesBlock,
      tires: tiresBlock,
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      let { data } = await Api.getServicesPage()

      this.priceList = data

      let response = await Api.getTiresServices()

      this.tiresList = response.data
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false
    }
  },
}
</script>

<style lang="sass" scoped>
.services
  padding: 100px $desktopHorizontalPaddings 0
  @media (max-width: 1023px)
    padding: 60px $mobileHorizontalPaddings 0

.tires
  padding: 0 $desktopHorizontalPaddings $desktopVerticalPaddings
  @media (max-width: 1023px)
    padding: 0 $mobileHorizontalPaddings $mobileVerticalPaddings
</style>
