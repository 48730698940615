<template>
  <section class="trademarks">
    <h2 class="trademarks__title font-title">{{ content.title }}</h2>
    <div class="trademarks__description font2-18">
      {{ content.description }}
    </div>
    <div class="trademarks__table">
      <div
        v-for="(item, index) in content.trademarks"
        :key="index"
        class="trademarks__table-item"
      >
        <img :src="imageUrl(item)" alt="header" class="logo" />
      </div>
    </div>
  </section>
</template>

<script>
import { trademarksBlock } from '@/content/sto'
import { imageUrl } from '@/helpers/imageLoading'

export default {
  name: 'StoTrademarks',
  data() {
    return {
      content: trademarksBlock,
      imageUrl,
    }
  },
}
</script>

<style lang="sass" scoped>
.trademarks
  padding: 100px $desktopHorizontalPaddings 0
  @media (max-width: 1023px)
    padding: $mobileVerticalPaddings $mobileHorizontalPaddings 0
  &__title
    width: 75%
    margin-bottom: 3rem
    @media (max-width: 1023px)
      width: 100%
      margin-bottom: 1.6rem
  &__description
    margin-bottom: 6rem
    @media (max-width: 1023px)
      margin-bottom: 3rem
  &__table
    display: flex
    justify-content: space-between
    flex-wrap: wrap
    @media (max-width: 1023px)
      flex-direction: column
      justify-content: flex-start
      gap: 1.6rem

    &-item
      display: flex
      align-items: center
      width: 24.5%
      height: 16rem
      margin-bottom: 1.6rem
      padding: 3rem 3rem
      background: $backgroundGray
      border-radius: 30px
      @media (max-width: 1023px)
        width: 100%
        height: 12rem
        margin-bottom: 0
        border-radius: 16px
      .logo
        width: 100%
        height: 100%
        object-position: center
        object-fit: contain
</style>
