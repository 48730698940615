import { mailTo } from '@/content/emailsList'

export const headerBlock = {
  image: 'home/home-header.jpg',
  title: 'Торгово-сервисная компания грузовой техники',
  button: 'Задать вопрос',
  mailTo: mailTo.home,
}

export const actionBlock = [
  {
    id: 1,
    title: 'АКЦИЯ',
    description:
      'При покупке масла QUALITET для проведения ТО, работы по замене бесплатно',
    terms: 'Не пропустите. Действует до конца декабря 2023 года.',
    image: 'home/home-action1.png',
  },
  {
    id: 2,
    title: 'АКЦИЯ',
    description:
      'С 1 декабря 2023 года снижена цена на всю линейку автомобилей          ',
    terms: 'Подробности в разделе продаж автомобилей.          ',
    image: 'home/home-action2.png',
  },
]
export const servicesBlock = {
  title: 'Чем мы занимаемся',
  tiles: [
    {
      icon: 'home/services1.png',
      text: 'Продажа грузовой техники HONGYAN',
      button: 'Каталог',
      page: 'Catalog',
    },
    {
      picture: 'home/services-img1.png',
    },
    {
      icon: 'home/services2.png',
      text: 'Сервисный центр по ремонту грузовых автомобилей',
      button: 'Грузовое СТО',
      page: 'TruckSTO',
    },
    {
      picture: 'home/services-img2.png',
    },
    {
      icon: 'home/services3.png',
      text: 'Собственная территория 17га под организацию автостоянки грузового транспорта',
      button: 'Услуги терминала',
      page: 'Services',
    },
    {
      picture: 'home/services-img3.png',
    },
  ],
}
